@import url(https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background-color: #282c34;
  overflow: hidden;
  height: 100%;
}

.App-logo {
  height: 18vmin;
}

body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  height: 100%;
  overflow: auto;
}

.App-link {
  font-family: "Homemade Apple", cursive;
  background-color: #282c34;
  border: none;
  font-size: 3vh;
  color: white;
  text-decoration: underline;
  border-radius: 5px;
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 3s;
  height: 3vh;
}
.App-link:hover {
  cursor: pointer;
  opacity: 0.8;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */

.subtitle {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 2.5vh;
  height: 2vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 8vh;
}

.work-page {
  text-align: center;
  width: 100vw;
  display: grid;
  grid-template-columns: 50% 50%;
}

.intro {
  padding-top: 40%;
  padding-left: 10%;
}

.connect {
  padding-left: 25%;
  padding-top: 2vh;
  font-size: 2vh;
  font-family: "Dosis", sans-serif;
}

.work-area {
  height: 100vh;
  background-color: #181a1c;
  border-radius: 5px;
  text-align: center;
}

.icons {
  display: grid;
  grid-template-columns: 8% 8% 8% 8% 8%;
  padding-top: 2vh;
  padding-bottom: 2vh;
  grid-column-gap: 1vw;
  padding-left: 17%;
}

h1 {
  font-size: 4vh;
}

.icon-item {
  max-height: 4vh;
  max-width: 4vh;
  -webkit-filter: invert(100%);
          filter: invert(100%);
  opacity: 0.8;
}

.icon-item:hover {
  cursor: pointer;
  opacity: 1;
}

.samples {
  padding-top: 10%;
  font-family: "Dosis", sans-serif;
  font-size: 2vh;
}

.card-grid-view {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 10%;
}

.card-wrapper {
  align-items: center;
}

.card-body {
  display: grid;
  border-radius: 2vh;
  color: #a9a9a9;
  margin: 0.5vh;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.card-body:hover {
  box-shadow: 0 0 4em 0 rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}
.card-body:active {
  -webkit-transform: scale(1.01) translateY(0.2rem);
          transform: scale(1.01) translateY(0.2rem);
}

.card-body img {
  position: relative;
  top: -25%;
  width: 90%;
}

.card-img {
  display: inline-block;
}

img {
  border-radius: 0.5vw;
}

.project-img {
  padding-top: 0.56%;
}

.card-content h3 {
  margin: 0;
  padding: 0;
  font-size: 3vh;
  padding-top: 5%;
}

.content-description {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 1vh;
  padding-top: 3%;
}

#s1 {
  -webkit-filter: grayscale(100%) invert(100%);
          filter: grayscale(100%) invert(100%);
  stroke-dasharray: 6692;
  stroke-dashoffset: 6692;
  -webkit-animation: sign 1.5s linear;
          animation: sign 1.5s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

#s2 {
  -webkit-filter: grayscale(100%) invert(100%);
          filter: grayscale(100%) invert(100%);
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  -webkit-animation: sign 0.2s linear;
          animation: sign 0.2s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
}

#s3 {
  -webkit-filter: grayscale(100%) invert(100%);
          filter: grayscale(100%) invert(100%);
  stroke-dasharray: 9990;
  stroke-dashoffset: 9990;
  -webkit-animation: sign 1.5s linear;
          animation: sign 1.5s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

@-webkit-keyframes sign {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes sign {
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 1200px) {
  .intro {
    padding-top: 20%;
  }
  .connect {
    padding-top: 20%;
  }
  .icons {
    padding-left: 10%;
    grid-template-columns: 15% 15% 15% 15% 15%;
  }
  .work-area {
    width: 100%;
    height: 100%;
  }
  .card-grid-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-img {
    display: inline-block;
    padding-right: 0;
  }
  img {
    height: 100%;
  }
}

@media (max-width: 800px) {
  .work-page {
    display: flex;
    flex-direction: column;
  }
  .work-area {
    width: 100%;
    height: 100%;
  }
  .intro {
    padding-top: 20%;
  }
  .card-grid-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .connect {
    padding-top: 10%;
  }
  .icons {
    padding-left: 10%;
    padding-bottom: 20%;
  }
  .card-img {
    display: inline-block;
    padding-right: 0;
  }
  img {
    height: 100%;
  }
}

